<!--
 *@author huhongyun 
 *@date 2021/11/3
 *@component 登录
-->
<template>
  <div class="ykc-login-page-container">
    <el-scrollbar
      noresize
      :native="false"
      tag="div"
      wrap-class="ykc-login-scrollbar-wrapper"
      view-class="ykc-login-scrollbar-view-box">
      <div class="ykc-login-wrapper" ref="ykcLoginPage">
        <!--左侧安全区-->
        <div class="safe-area-left"></div>
        <!--品牌信息-->
        <div class="brand-container">
          <img class="ykc-logo" v-if="getLoginLogo" :src="getLoginLogo" alt="" />
          <div class="ykc-banner">
            <img class="svg-icon svg-yellow-light-1" :src="YellowLight1" alt="" />
            <img class="svg-icon svg-yellow-light-2" :src="YellowLight2" alt="" />
            <img class="svg-icon svg-purple-light-1" :src="PurpleLight1" alt="" />
            <img class="svg-icon svg-blue-light-1" :src="BlueLight1" alt="" />
          </div>
          <div class="ykc-record-number">
            备案号：
            <a target="_blank" href="http://beian.miit.gov.cn/">沪ICP备15000733号-1</a>
            © www.evchong.com All rights reserved.
          </div>
          <div class="ykc-copy-right">版权所有：上海森通智达新能源技术服务有限公司</div>
        </div>
        <!--中间安全区-->
        <div class="safe-area-middle"></div>
        <!--登录-->
        <div class="login-container">
          <!--控制点状图-->
          <div class="dot-container"></div>
          <div class="login-container-content">
            <div class="welcome-word">欢迎进入</div>
            <div class="platform-name">淘淘充内部运营平台</div>
            <el-tabs v-model="activeTab">
              <el-tab-pane label="账密登录" name="account">
                <ykc-form
                  :model="ruleForm"
                  status-icon
                  :rules="rules"
                  ref="ruleForm"
                  label-width="100px"
                  style="margin-top: 10px"
                  class="login-form">
                  <!--用户名-->
                  <ykc-form-item label="" prop="accountNo" class="login-account-form-item">
                    <ykc-input
                      @keyup.enter.native="submitForm"
                      @change="onInputChange"
                      v-model="ruleForm.accountNo"
                      placeholder="请输入账号"></ykc-input>
                  </ykc-form-item>
                  <!--密码-->
                  <ykc-form-item label="" prop="accountPwd">
                    <ykc-input
                      @keyup.enter.native="submitForm"
                      @change="onInputChange"
                      type="password"
                      v-model="ruleForm.accountPwd"
                      autocomplete="off"
                      placeholder="请输入密码"></ykc-input>
                  </ykc-form-item>

                  <!--验证码-->
                  <ykc-form-item label="" prop="verifyCode" v-if="loginType === 10">
                    <div class="flex">
                      <ykc-input
                        maxlength="6"
                        placeholder="请输入验证码"
                        v-model="ruleForm.verifyCode"
                        style="flex: 1; margin-right: 10px"></ykc-input>
                      <ykc-button v-if="!getCodeState" @click="verifyCodeFun">
                        发送验证码
                      </ykc-button>
                      <div v-else class="verification" :style="codeStyle">{{ codeHtml }}</div>
                    </div>
                  </ykc-form-item>

                  <!--图形码-->
                  <ykc-form-item label="" prop="verifyCode" v-else-if="loginType === 11">
                    <div class="flex">
                      <ykc-input
                        maxlength="6"
                        placeholder="请输入图形码"
                        v-model="ruleForm.verifyCode"
                        style="flex: 1; margin-right: 10px"></ykc-input>
                      <img
                        class="verification"
                        :src="LoginVerifyType.imgBase64"
                        alt="暂无图片"
                        @click="getLoginVerifyTypeOrImgVerifyCode" />
                    </div>
                  </ykc-form-item>

                  <div class="verify-slot" v-else></div>

                  <div class="record-passwd-wrapper">
                    <div @click="changeRecordState">
                      <img
                        class="record-passwd-icon"
                        :src="record ? loginCheckboxChecked : loginCheckboxDefault"
                        alt="" />
                      <span class="record-passwd-tip">记住账号和密码</span>
                    </div>
                  </div>

                  <ykc-form-item>
                    <ykc-button
                      class="login-btn"
                      :class="loginBtnActive"
                      type="primary"
                      @keyup.enter.native="submitForm"
                      @click="submitForm">
                      登录
                    </ykc-button>
                  </ykc-form-item>
                </ykc-form>
              </el-tab-pane>
              <!-- <el-tab-pane label="二维码登录" name="qrcode" class="qrcode">
                <div
                  style="height: 323px; width: 300px; margin-top: -30px"
                  id="login_container"></div>
              </el-tab-pane> -->
            </el-tabs>
          </div>
        </div>
        <!--右侧安全区-->
        <div class="safe-area-right"></div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
  import CryptoJs from 'crypto-js';
  import { mapGetters, mapMutations } from 'vuex';
  import loginBg from '../assets/login/login-bg.png';
  import dot1366 from '../assets/login/dot_1366.png';
  import dot1920 from '../assets/login/dot_1920.png';
  import loginCheckboxChecked from '../assets/login/login_checkbox_checked.png';
  import loginCheckboxDefault from '../assets/login/login_checkbox_default.png';
  import YellowLight1 from '../assets/login/yellow1.svg';
  import BlueLight1 from '../assets/login/blue1.svg';
  import PurpleLight1 from '../assets/login/purple1.svg';
  import { commonUtils, sysDict, sysCnf } from '@/service/apis';
  import { sessionSetItem, sessionGetItem, localGetItem, timeCountDown } from '@/utils';

  /**
   * 防抖函数
   */
  function debounce(func, wait = 1000, immediate = true) {
    let timeout = null;
    return function fn() {
      // eslint-disable-next-line prefer-rest-params
      const args = arguments;
      if (timeout) {
        clearTimeout(timeout);
      }
      // 首次立即执行
      if (immediate) {
        const nowExe = !timeout;
        timeout = setTimeout(() => {
          timeout = null;
        }, wait);
        if (nowExe) {
          func.apply(this, args);
        }
      } else {
        // 首次延迟执行
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, wait);
      }
    };
  }

  // eslint-disable-next-line
  function DDLogin(a) {
    let e;
    const c = document.createElement('iframe');
    let d = `https://login.dingtalk.com/login/qrcode.htm?goto=${a.goto}`;
    // eslint-disable-next-line
    (d += a.style ? `&style=${encodeURIComponent(a.style)}` : ''),
      (d += a.href ? `&href=${a.href}` : ''),
      (c.src = d),
      (c.frameBorder = '0'),
      (c.allowTransparency = 'true'),
      (c.scrolling = 'no'),
      (c.width = a.width ? `${a.width}px` : '365px'),
      (c.height = a.height ? `${a.height}px` : '400px'),
      (e = document.getElementById(a.id)),
      (e.innerHTML = ''),
      e.appendChild(c);
  }
  export default {
    name: 'login',
    components: {},
    data() {
      return {
        activeTab: 'account',
        codeHtml: '', // 验证码倒计时
        setTime: 60, // 倒计时时间
        getCodeState: false, // 是否已发送验证码
        loginBg,
        dot1366,
        dot1920,
        loginCheckboxChecked,
        loginCheckboxDefault,
        YellowLight1,
        YellowLight2: YellowLight1,
        BlueLight1,
        PurpleLight1,
        // 菜单数据
        menuData: [],
        // 深度遍历菜单数据
        DFSMenuData: [],
        LoginVerifyType: {}, // 登录方式
        codeStyle: {
          color: '#4A4A4A',
        }, // 修改验证码文字样式
        // 登录表单
        ruleForm: {
          accountNo: '',
          accountPwd: '',
        },
        // 表单规则
        rules: {
          accountNo: [{ required: true, message: '请输入账号', trigger: 'blur' }],
          accountPwd: [{ required: true, message: '请输入密码', trigger: 'blur' }],
          verifyCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        },
        // 是否选中记住账号和密码
        record: true,
        // selectCheckbox: [],
        // target: '',
        // 表单数据是否是合法的
        formValid: false,
      };
    },
    created() {
      this.submitForm = debounce(this.submitForm, 1000, true);
      this.simpleCnf();
      this.getLoginVerifyTypeOrImgVerifyCode();
      this.readRecord();
      this.codeHtml = '获取验证码';
      const loginTime = localGetItem('loginTime');
      // 查看有没有保存的验证码时间
      if (loginTime) {
        // 获取当前的时间
        const now = new Date().getTime() / 1000;
        if (parseInt(loginTime - now, 10) > 0) {
          this.setTime = parseInt(loginTime - now, 10);
          this.getCodeState = true;
          // 倒计时
          timeCountDown({
            vue: this,
            times: null,
            saveKey: 'loginTime',
          });
        }
      }
    },
    mounted() {
      this.target = this.$refs.ykcLoginPage;
      this.onInputChange();
    },
    activated() {},
    methods: {
      ...mapMutations(['setDic', 'setLoginLogo']),
      // logo查询
      simpleCnf() {
        sysCnf
          .simpleCnf({})
          .then(res => {
            this.setLoginLogo(res.loginLogoPath);
          })
          .catch(() => {
            this.setLoginLogo(null);
          });
      },
      readRecord() {
        const accountNo = localStorage.getItem('accountNo');
        const accountPwd = localStorage.getItem('accountPwd');
        if (accountNo && accountPwd) {
          this.ruleForm.accountNo = accountNo;
          this.ruleForm.accountPwd = CryptoJs.AES.decrypt(accountPwd, 'accountPwd').toString(
            CryptoJs.enc.Utf8
          );
        }
      },
      /**
       * @desc  获取登录方式
       * */
      getLoginVerifyTypeOrImgVerifyCode() {
        commonUtils.getLoginVerifyTypeOrImgVerifyCode({}).then(res => {
          if (res.imgBase64) {
            res.imgBase64 = `data:image/png;base64,${res.imgBase64}`;
          }
          this.LoginVerifyType = res;
        });
      },
      /**
       * @desc 发送验证码
       * */
      verifyCodeFun() {
        const errors = [];
        this.$refs.ruleForm.validateField(['accountNo', 'accountPwd'], error => {
          errors.push(error);
          if (errors.length === 2 && !errors.find(item => !!item)) {
            if (this.getCodeState) {
              return;
            }
            commonUtils
              .sendVerifyCodeByAccount({
                loginClient: 1,
                verifyCodeScene: 1,
                account: this.ruleForm.accountNo,
                accountPwd: this.ruleForm.accountPwd,
              })
              .then(() => {
                this.getCodeState = true;
                timeCountDown({
                  vue: this,
                  times: null,
                  saveKey: 'loginTime',
                });
              })
              .catch(err => {
                console.log(err);
              });
          }
        });
      },
      /**
       * @desc 监听 input 输入框的change
       * */
      onInputChange() {
        this.formValid =
          /^\w{3,20}$/.test(this.ruleForm.accountNo) &&
          /[a-z0-9A-Z]{6,16}$/.test(this.ruleForm.accountPwd);
      },
      /*   checkboxChange(e) {
        YkcWarningTips(`选择value为${e.join()}`);
      }, */
      changeRecordState() {
        this.record = !this.record;
      },
      /**
       * @desc 处理登录逻辑
       * */
      doLogin() {
        const params = {
          loginType: this.loginType,
          loginClient: 1,
          account: this.ruleForm.accountNo,
          credentials: this.ruleForm.accountPwd,
        };
        if (this.loginType !== 1) {
          params.verifyCode = this.ruleForm.verifyCode;
        }
        if (this.loginType === 11) {
          params.verifyCodeID = this.LoginVerifyType.imgVerifyCodeId;
        }
        commonUtils
          .login(params)
          .then(response => {
            console.log('登录成功', response);
            if (response) {
              sessionSetItem('accountNo', this.ruleForm.accountNo);
              sessionSetItem('roleType', response.roleType);
              sessionSetItem('accountId', response.accountId);
              sessionSetItem('menuData', JSON.stringify(response.menus));
              sessionSetItem('propertyFlag', response.propertyFlag);
              sessionSetItem('phoneNo', response.phoneNo);
              if (response.propertyFlag) {
                sessionSetItem('propertyId', response.propertyId);
              }
              sessionSetItem('token', response.token);
              if (this.record) {
                localStorage.setItem('accountNo', this.ruleForm.accountNo);
                localStorage.setItem(
                  'accountPwd',
                  CryptoJs.AES.encrypt(this.ruleForm.accountPwd, 'accountPwd')
                );
              } else {
                localStorage.removeItem('accountNo');
                localStorage.removeItem('accountPwd');
              }
              this.queryCodePermission();
            }
          })
          .catch(err => {
            console.error('login error::', err);
            this.getLoginVerifyTypeOrImgVerifyCode();
          });
      },
      queryCodePermission() {
        sysDict
          .queryCodePermission(sessionGetItem('accountId'))
          .then(res => {
            sessionSetItem('codePermission', JSON.stringify(res));
            this.menuList = res || [];
            this.login();
          })
          .catch(() => {
            sessionSetItem('codePermission', null);
          });
      },
      submitForm() {
        // eslint-disable-next-line consistent-return
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.formValid = true;
            try {
              this.doLogin();
            } catch (e) {
              console.error(e);
            }
          } else {
            console.error('error submit!!');
            this.formValid = false;
          }
        });
      },
      login() {
        window.open('/', '_self');
      },
    },
    computed: {
      ...mapGetters(['getLoginLogo']),
      loginBtnActive() {
        return {
          'btn-active': this.formValid,
        };
      },
      loginType() {
        if (Number(this.LoginVerifyType.loginVerifyType) === 1) {
          return 10;
        }
        if (Number(this.LoginVerifyType.loginVerifyType) === 2) {
          return 11;
        }
        return 1;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @use 'sass:math';
  @import '@/components/ykc-ui/styles/var.scss';
  @keyframes light-move {
    0% {
      transform: translate(0px, 0px);
    }
    50% {
      transform: translate(0px, -10px);
    }
    100% {
      transform: translate(0px, 0px);
    }
  }
  .el-button.ykc-button.login-btn.btn-active {
    background: #4d8bfd;
    opacity: 1;
  }
  .record-passwd-wrapper {
    //display: flex;
    align-items: center;
    // 记住密码图标样式
    .record-passwd-icon {
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
    // 记住密码提示文字样式
    .record-passwd-tip {
      font-size: 12px;
      color: #868a98;
      letter-spacing: 0;
      font-weight: 200;
      margin-left: 6px;
    }
  }

  .ykc-login-page-container {
    height: 100%;
    text-align: left;
    position: relative;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    ::v-deep .el-scrollbar {
      height: 100%;
    }
  }
  .ykc-login-page-container ::v-deep .ykc-login-scrollbar-wrapper.el-scrollbar__wrap {
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
  }
  ::v-deep .ykc-login-scrollbar-view-box {
    width: 100%;
    height: 100%;
  }
  // 重置 form input border 样式
  ::v-deep .ykc-input.el-input .el-input__inner {
    border: 1px solid #ececec;
    border-radius: 4px;
  }
  ::v-deep .ykc-checkbox.remember-checkbox .el-checkbox__label {
    font-size: 12px;
    color: #868a98;
    letter-spacing: 0;
    line-height: 17px;
    height: 17px;
    padding-left: 6px;
  }

  ::v-deep .ykc-form-item .el-form-item.is-error .ykc-input.el-input .el-input__inner {
    border: 1px solid #d0021b;
  }
  //通用样式
  .ykc-login-wrapper {
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    width: 100vw;
    height: 100vh;
    min-height: 650px;
    box-sizing: border-box;

    background-image: url('~@/assets/login/login-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    //overflow: auto;
    align-items: center;

    // .login-container {
    // }

    .verification {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 96px;
    }

    .verify-slot {
      height: 60px;
    }
  }

  // 1366 及 以下
  @media screen and (max-width: 1366px) {
    // 各个块的比例
    .ykc-login-wrapper {
      .safe-area {
        &-left {
          flex: 1;
        }
        &-middle {
          flex: 1.08;
        }
        &-right {
          flex: 1;
        }
      }
      .brand-container {
        flex: 2.83;
      }
      .login-container {
        flex: 2.625;
      }
    }
    .record-passwd-wrapper {
      margin-top: 30px;
    }
    // 用户输入账号报错提示文字被遮盖。样式修改
    .ykc-form-item.login-account-form-item {
      ::v-deep .el-form-item.is-error {
        margin-bottom: 32px;
      }
    }
    .ykc-login-wrapper {
      width: 1366px;
      overflow: auto;
      margin: auto;
      // 品牌样式
      .brand-container {
        width: 608px;
        height: 552px;
        // 上间距
        //top: 170px;
        // 左间距
        //left: 160px;
        // logo 样式
        .ykc-logo {
          height: 40px;
          display: block;
        }
        // banner 图样式
        .ykc-banner {
          position: relative;
          width: 453px;
          height: 304px;
          margin-top: 34px;
          background: url('~@/assets/login/banner_1366.png');
          background-size: cover;
          .svg-icon {
            width: 5px;
            height: 45px;
            animation: light-move 1s infinite;
          }
          // 黄1
          .svg-yellow-light-1 {
            position: absolute;
            top: 10px;
            left: 20px;
          }
          // 黄2
          .svg-yellow-light-2 {
            position: absolute;
            top: 94px;
            left: 220px;
            animation-delay: 0.2s;
          }
          // 紫1
          .svg-purple-light-1 {
            position: absolute;
            top: 30px;
            right: 153px;
            animation-delay: 0.4s;
          }
          // 蓝1
          .svg-blue-light-1 {
            position: absolute;
            bottom: 36px;
            left: 60px;
            animation-delay: 0.6s;
          }
        }
        .ykc-record-number,
          // 版权
        .ykc-copy-right {
          font-size: 10px;
          color: #6d7278;
          letter-spacing: 0;
          line-height: 20px;
        }
        // 备案号
        .ykc-record-number {
          margin-top: 10px;
          a {
            color: #6d7278;
            text-decoration: none;
          }
        }
      }

      // 登录表单样式
      .login-container {
        position: relative;
        display: flex;
        // 点状图
        .dot-container {
          display: inline-flex;
          position: absolute;
          width: 68px;
          height: 52px;
          background: url('~@/assets/login/dot_1920.png');
          background-size: cover;
          //right: 160 + 420 - math.div(68, 2) + px;
          //top: 175 - math.div(52, 2) + px;
          left: -1 * math.div(68, 2) + px;
          top: -1 * math.div(52, 2) + px;
        }
        .login-container-content {
          z-index: 1;
          padding: 60px;
          box-sizing: border-box;
          //position: absolute;
          width: 420px;
          height: 492px;
          height: 500px;
          // 上间距
          //top: 175px;
          // 左间距
          //left: 786px;
          right: 160px;
          background: #ffffff;
          box-shadow: 40px 40px 140px -10px rgba(0, 92, 255, 0.3);
          filter: blur(6);
          // 欢迎词
          .welcome-word {
            font-size: 16px;
            color: $_000000;
            letter-spacing: 1.6px;
            font-weight: 200;
          }
          // 平台名称
          .platform-name {
            font-size: 22px;
            color: $_000000;
            letter-spacing: 1.6px;
            font-weight: 600;
            margin-top: 10px;
          }
          .el-tab-pane.qrcode {
            display: flex;
            justify-content: center;
          }
        }
      }
      // 登录表单
      .login-form {
        // margin-top: 30px;
        .login-btn {
          margin-top: 20px;
          width: 300px;
          height: 40px;
          opacity: 0.5;
          background: #4d8bfd;
          border-radius: 4px;
          font-size: 14px;
          color: $_FFFFFF;
          letter-spacing: 0;
          text-align: center;
        }
        // 记住账号和密码
        .remember-checkbox {
          margin-top: 30px;
        }
        .ykc-input.el-input {
          height: 40px;
          background: $_FFFFFF;
          color: #868a98;
          letter-spacing: 0;
          .el-input__inner {
            color: #868a98;
          }
        }
        .ykc-input.el-input .el-input__inner {
          color: #868a98;
          letter-spacing: 0;
        }
      }
    }
  }
  // 1367 及以上
  @media screen and (min-width: 1367px) {
    // 各个块的比例
    .ykc-login-wrapper {
      .safe-area {
        &-left {
          flex: 1.5;
        }
        &-middle {
          flex: 1;
        }
        &-right {
          flex: 1.5;
        }
      }
      .brand-container {
        flex: 3;
      }
      .login-container {
        flex: 2.7;
      }
    }
    .ykc-form-item.login-account-form-item {
      ::v-deep .el-form-item.is-error {
        margin-bottom: 32px;
      }
    }
    .ykc-login-wrapper {
      //min-width: 1366px;
      //min-height: 768px;
      // 品牌样式
      .brand-container {
        //position: absolute;
        width: 608px;
        height: 552px;
        .ykc-logo {
          height: 46px;
          display: block;
        }
        // banner 图样式
        .ykc-banner {
          position: relative;
          width: 608px;
          height: 408px;
          margin-top: 48px;
          background: url('~@/assets/login/banner_1920.png');
          background-size: cover;
          .svg-icon {
            width: 8px;
            height: 68px;
            animation: light-move 1s infinite;
          }
          // 黄1
          .svg-yellow-light-1 {
            position: absolute;
            top: 10px;
            left: 20px;
          }
          // 黄2
          .svg-yellow-light-2 {
            position: absolute;
            top: 114px;
            left: 296px;
            animation-delay: 0.2s;
          }
          // 紫1
          .svg-purple-light-1 {
            position: absolute;
            top: 20px;
            right: 195px;
            animation-delay: 0.4s;
          }
          // 蓝1
          .svg-blue-light-1 {
            position: absolute;
            bottom: 57px;
            left: 60px;
            animation-delay: 0.6s;
          }
        }
        .ykc-record-number,
          // 版权
        .ykc-copy-right {
          font-size: 10px;
          color: #6d7278;
          letter-spacing: 0;
          line-height: 20px;
        }
        // 备案号
        .ykc-record-number {
          margin-top: 10px;
          a {
            color: #6d7278;
            text-decoration: none;
          }
        }
      }

      // 登录表单样式
      .login-container {
        position: relative;
        display: flex;
        // 点状图
        .dot-container {
          position: absolute;
          width: 128px;
          height: 98px;
          background: url('~@/assets/login/dot_1920.png');
          background-size: cover;
          left: -1 * math.div(128, 2) + px;
          top: -1 * math.div(98, 2) + px;
        }
        .login-container-content {
          z-index: 1;
          padding: 60px 80px;
          box-sizing: border-box;
          width: 520px;
          // height: 492px;
          height: 520px;
          background: #ffffff;
          box-shadow: 40px 40px 140px -10px rgba(0, 92, 255, 0.3);
          filter: blur(6);
        }
        // 欢迎词
        .welcome-word {
          font-size: 20px;
          color: $_000000;
          letter-spacing: 2px;
          font-weight: 200;
        }
        // 平台名称
        .platform-name {
          font-size: 26px;
          color: $_000000;
          letter-spacing: 2px;
          font-weight: 600;
          margin-top: 10px;
        }
        .el-tab-pane.qrcode {
          display: flex;
          justify-content: center;
        }
      }
      // 登录表单
      .login-form {
        // margin-top: 30px;
        .login-btn {
          margin-top: 20px;
          width: 360px;
          height: 40px;
          opacity: 0.5;
          background: #4d8bfd;
          border-radius: 4px;
          font-size: 14px;
          color: $_FFFFFF;
          letter-spacing: 0;
          text-align: center;
        }
        // 记住账号和密码
        .remember-checkbox {
          margin-top: 40px;
        }
        .ykc-input.el-input {
          height: 40px;
          background: $_FFFFFF;
          color: #868a98;
          letter-spacing: 0;
          .el-input__inner {
            color: #868a98;
          }
        }
        .ykc-input.el-input .el-input__inner {
          color: #868a98;
          letter-spacing: 0;
        }
      }
    }
  }
  // 1920 及以上
  @media screen and (min-width: 1920px) {
    // .ykc-login-wrapper {
    //   //min-width: 1920px;
    //   //min-height: 1080px;
    // }
  }
</style>
